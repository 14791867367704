//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import notificationMixin from '@/mixins/notification'
import callLoggerFormMixin from '@/mixins/callLoggerForm'
import dataTableMixin from '@/mixins/dataTable'

const NEW_DATA = {
  callLog: {},
  callLogger: {}
}

export default {
  mixins: [notificationMixin, callLoggerFormMixin, dataTableMixin],
  data () {
    return {
      results: [],
      loading: false,
      dialogsLoading: false,
      search: '',
      serverItemsLength: 0,
      forms: { currentLog: NEW_DATA },
      dialogs: {
        update: {
          title: this.$i18n.t('callLogsDialog.dialogs.updateItem.title'),
          confirmMessage: this.$i18n.t('callLogsDialog.dialogs.updateItem.confirmMessage'),
          successMessage: this.$i18n.t('callLogsDialog.dialogs.updateItem.successMessage'),
          closeAriaLabel: this.$i18n.t('callLogsDialog.dialogs.updateItem.closeAriaLabel')
        },
        delete: {
          title: this.$i18n.t('callLogsDialog.dialogs.deleteItem.title'),
          confirmMessage: this.$i18n.t('callLogsDialog.dialogs.deleteItem.confirmMessage'),
          successMessage: this.$i18n.t('callLogsDialog.dialogs.deleteItem.successMessage'),
          closeAriaLabel: this.$i18n.t('callLogsDialog.dialogs.deleteItem.closeAriaLabel')
        }
      },
      footerProps: {
        itemsPerPageOptions: [500, 1000, 2000],
        options: {
          page: 1,
          itemsPerPage: 500
        }
      },
      searchAfter: {}
    }
  },
  computed: {
    headers () {
      return [
        { text: this.$i18n.t('callLogsDialog.headers.actions'), value: 'actions', sortable: false, width: 60 },
        { text: this.$i18n.t('callLogsDialog.headers.createDate'), value: 'createDate', sortable: true, width: 120 },
        { text: this.$i18n.t('callLogsDialog.headers.createTime'), value: 'createTime', sortable: true },
        { text: this.$i18n.t('callLogsDialog.headers.userId'), value: 'csrDisplay' },
        { text: this.$i18n.t('callLogsDialog.headers.firstName'), value: 'callLog.firstName' },
        { text: this.$i18n.t('callLogsDialog.headers.lastName'), value: 'callLog.lastName' },
        { text: this.$i18n.t('callLogsDialog.headers.channel'), value: 'callLog.callChannel' },
        { text: this.$i18n.t('callLogsDialog.headers.product'), value: 'callLogger.quoter' },
        { text: this.$i18n.t('callLogsDialog.headers.qrn'), value: 'callLogger.qrn' },
        { text: this.$i18n.t('callLogsDialog.headers.profileModified'), value: 'profileModified' },
        { text: this.$i18n.t('callLogsDialog.headers.kPlus'), value: 'kPlus' },
        { text: this.$i18n.t('callLogsDialog.headers.callbackDate'), value: 'callLog.callbackDate' },
        { text: this.$i18n.t('callLogsDialog.headers.callbackTime'), value: 'callLog.callbackTime' },
        { text: this.$i18n.t('callLogsDialog.headers.attempt'), value: 'attemptDisplay' },
        { text: this.$i18n.t('callLogsDialog.headers.callType'), value: 'callLog.callType' },
        { text: this.$i18n.t('callLogsDialog.headers.callDetails'), value: 'callLog.callDetails' },
        { text: this.$i18n.t('callLogsDialog.headers.leadType'), value: 'callLog.leadType' },
        { text: this.$i18n.t('callLogsDialog.headers.additionalDetails'), value: 'callLog.notes' },
        { text: this.$i18n.t('callLogsDialog.headers.language'), value: 'callLog.language' },
        { text: this.$i18n.t('callLogsDialog.headers.postalCode'), value: 'callLog.postalCode' },
        { text: this.$i18n.t('callLogsDialog.headers.dateOfBirth'), value: 'callLog.dateOfBirth', width: 120 },
        { text: this.$i18n.t('callLogsDialog.headers.gender'), value: 'callLog.gender' },
        { text: this.$i18n.t('callLogsDialog.headers.drivers'), value: 'callLog.drivers' },
        { text: this.$i18n.t('callLogsDialog.headers.vehicles'), value: 'callLog.vehicles' },
        { text: this.$i18n.t('callLogsDialog.headers.updatedAt'), value: 'updatedAt', width: 120 },
        { text: this.$i18n.t('callLogsDialog.headers.lastHandledBy'), value: 'lastHandledByDisplay' },
        { text: this.$i18n.t('callLogsDialog.headers.phone'), value: 'callLog.phone' }
      ]
    },
    callLogs () {
      return this.results.map((itm) => {
        const callLog = JSON.parse(itm.callLog)
        return {
          callLogId: `${itm.id}-${itm.attempt}`,
          createDate: this.$dayjs(itm.createdAt).format('YYYY-MM-DD'),
          createTime: this.$dayjs(itm.createdAt).format('HH:mm:ss'),
          updatedAt: this.$dayjs(itm.updatedAt).format('YYYY-MM-DD HH:mm'),
          profileModified: callLog.profileModified ? this.$i18n.t('yes') : this.$i18n.t('no'),
          kPlus: callLog.kPlus ? this.$i18n.t('yes') : this.$i18n.t('no'),
          csrDisplay: this.getFormatedUsername(itm.csr),
          lastHandledByDisplay: this.getFormatedUsername(itm.lastHandledBy),
          attemptDisplay: callLog.cancelled ? -1 : itm.attempt,
          callLogger: {
            attempt: itm.attempt,
            csr: itm.csr,
            lastHandledBy: itm.lastHandledBy,
            id: itm.id,
            leadId: itm.leadId,
            qrn: itm.qrn,
            quoter: itm.quoter,
            source: itm.source
          },
          callLog
        }
      })
    },
    canOnlyManageOwnLogs () {
      return this.$auth.groups && this.$auth.groups.some(group => /^(Ambassador)$/.test(group)) && !this.$auth.groups.some(group => /^(Admin|CSRAdmin)$/.test(group))
    }
  },
  methods: {
    async refreshData () {
      this.loading = true
      this.results = []
      try {
        if (this.$permissions.groupCanReadCallLogs()) {
          const searchParams = {
            from: (this.footerProps.options.page - 1) * this.footerProps.options.itemsPerPage,
            size: this.footerProps.options.itemsPerPage,
            sort: JSON.stringify([{ createdAt: 'desc', 'qrn.keyword': 'desc', attempt: 'desc' }])
          }
          if (this.footerProps.options.page === 1) {
            this.searchAfter = {}
          } else if ((this.footerProps.options.page * this.footerProps.options.itemsPerPage) > 10000) {
            searchParams.search_after = JSON.stringify(this.searchAfter[this.footerProps.options.page - 1])
          }
          searchParams.query = JSON.stringify(this.searchQuery())
          const res = await this.$api.searchCallLogs(searchParams)
          this.results = res.hits
          this.searchAfter[this.footerProps.options.page] = res.searchAfter
          this.serverItemsLength = res.total
        }
      } catch (err) {
        console.error(err)
      }
      this.loading = false
    },
    searchQuery () {
      const search = this.$store.state.callLogger.search
      const query = { bool: { must: [] } }
      if (search.qrn) {
        query.bool.must.push({ match: { 'qrn.keyword': search.qrn } })
      } else {
        const date = this.$dayjs(search.date)
        const searchDate = date && date.isValid() ? date : this.$dayjs()
        query.bool.must.push({
          range: {
            createdAt: {
              gte: searchDate.hour(0).minute(0).second(0).millisecond(0).toISOString(),
              lte: searchDate.hour(23).minute(59).second(59).millisecond(999).toISOString()
            }
          }
        })
      }
      if (this.canOnlyManageOwnLogs) {
        query.bool.must.push({ match: { 'csr.keyword': this.$auth.email } })
      }
      if (this.search) {
        query.bool.must.push({
          bool: {
            should: [
              { wildcard: { 'qrn.keyword': `*${this.search}*` } },
              { wildcard: { 'callLog.firstName.keyword': `*${this.search}*` } },
              { wildcard: { 'callLog.lastName.keyword': `*${this.search}*` } },
              { wildcard: { 'quoter.keyword': `*${this.search.toUpperCase()}*` } }
            ]
          }
        })
      }
      return query
    },
    onInput ({ id, idx, value }) {
      this.$store.dispatch(`callLogger/updateCurrent${this.capitalizeFirstLetter(id)}`, { [idx]: value })
    },
    onChange ({ id, idx, value }) {
      this.$store.dispatch(`callLogger/updateCurrent${this.capitalizeFirstLetter(id)}`, { [idx]: value })
    },
    async closeMainDialog () {
      if (this.params) {
        await this.$store.dispatch('callLogger/refreshCallLogs', {
          ...this.params,
          leadId: this.$store.state.lead.lead.leadId
        })
        this.$root.$emit('update-call-logger-table')
      }
      this.$store.dispatch('callLogger/clearCurrentLog')
      this.$store.dispatch('callLogger/clearAlert')
      this.$store.dispatch('callLogger/showCallLogsDialog', false)
    },
    showActionDialog (actionType, dataType, data) {
      this.dialogs[actionType].show = true
      if (actionType.match(/^(update)$/)) {
        this.$store.dispatch('callLogger/clearCurrentLog')
        this.$store.dispatch('callLogger/updateCurrentCallLogger', { ...data.callLogger })
        this.$store.dispatch('callLogger/updateCurrentCallLog', { ...data.callLog })
      } else if (actionType.match(/^(delete)$/)) {
        this.forms[dataType] = Object.assign({}, data)
      }
    },
    async updateItem () {
      const currentLog = this.$store.state.callLogger.currentLog
      if (!this.$permissions.groupCanUpdateCallLogs() ||
        (this.canOnlyManageOwnLogs && this.$auth.email !== currentLog.callLogger.csr)
      ) {
        this.dialogs.update.show = false
        this.setNotification(this.$i18n.t('callLogsDialog.notifications.accessDenied'), 5000)
        return
      }
      this.dialogsLoading = true
      try {
        this.setAlert('info', 'loading')
        const valid = this.$refs?.callLoggerForm?.$refs?.callLoggerForm?.validate()
        if (valid) {
          const callbackTime = await this.setCallbackDateTime()
          const resp = await this.updateCallLog(true)
          if (resp?.status) {
            const regexRemoveFromOB = /^(Do not solicit|Transfer|Not interested|No English|Not registered owner|Paid lead|Duplicate|Aged-close|Sonnet-email sent|Sonnet-no email sent|CAA transfer|Onlia transfer|Onlia - email sent|Onlia - no email sent|Already with Scoop|Outside renewal time|Needs monthly payment)$/
            const attemptFilter = currentLog.callLogger.attempt
            if (callbackTime && this.isQrnFormat(currentLog.callLogger.qrn) && currentLog.callLogger.source) {
              await this.$api.reinsertLeadToOutbound({ ...currentLog.callLogger, callbackTime, attemptFilter })
            } else if (regexRemoveFromOB.test(currentLog.callLog.callDetails)) {
              await this.$api.removeLeadFromOutbound({ qrn: currentLog.callLogger.qrn, source: currentLog.callLogger.source })
            }
            await this.$api.addLatestCallLogToOutbound({ ...currentLog.callLogger, attemptFilter })
            this.dialogs.update.show = false
            this.setNotification(this.dialogs.update.successMessage, 5000)
            this.refreshData()
            this.$store.dispatch('callLogger/clearAlert')
          } else {
            this.setAlert('error', 'error')
          }
        } else {
          const errorInputs = this.$refs.callLoggerForm.$refs.callLoggerForm.inputs.filter(itm => itm.errorBucket?.length)
          this.setAlert('error', null, errorInputs[0].errorBucket[0])
        }
      } catch (err) {
        console.error(err)
      }
      this.dialogsLoading = false
    },
    async deleteItem () {
      if (!this.$permissions.groupCanDeleteCallLogs()) {
        this.dialogs.delete.show = false
        this.setNotification(this.$i18n.t('callLogsDialog.notifications.accessDenied'), 5000)
        return
      }
      this.dialogsLoading = true
      try {
        const resp = await this.$api.deleteCallLog(this.forms.currentLog.callLogger.id, this.forms.currentLog.callLogger.attempt)
        await this.$api.syncAttemptsOutbound(this.forms.currentLog.callLogger.qrn)
        if (resp?.data?.deleteCallLog && resp.data.deleteCallLog.includes(this.forms.currentLog.callLogger.id)) {
          this.setNotification(this.dialogs.delete.successMessage, 5000)
          this.dialogs.delete.show = false
          await this.refreshData()
        }
      } catch (err) {
        console.error(err)
      }
      this.dialogsLoading = false
    },
    getFormatedUsername (email) {
      if (!email) {
        return ''
      } else {
        // display full email of the user as per INS-2345
        return email.trim()
      }
      const userName = email.split('@')[0]
      const [fn, ln] = userName.split('.')
      return fn && ln ? `${fn} ${ln}` : userName
    }
  }
}
